import React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Query } from "../../../types/graphql-types"
import PortfolioItem from "../../components/Portfolio/PortfolioItem"
import PageTitle from "../../components/utils/PageTitle"
import { en_description } from "../../components/const"

const Portfolio: React.FC<PageProps<Query>> = ({ data, location }) => {
  return (
    <Layout selectPageName="Portfolio" location={location}>
      <SEO
        title="Portfolio"
        description={en_description}
        lang={"en"}
        twitterTitle={"D Capital | Portfolio"}
      />
      {data.microcmsPortfolioEn?.title && (
        <PageTitle
          title={data.microcmsPortfolioEn.title}
          key={data.microcmsPortfolioEn.id}
        />
      )}
      <div className={"portfolio-contents-wrapper"}>
        <div className={"investments-wrapper"}>
          {data.microcmsPortfolioEn &&
            data.microcmsPortfolioEn.portfolio?.map(
              item =>
                item && (
                  <PortfolioItem
                    key={`portfolio_item${item.title}`}
                    item={item}
                    isEn={true}
                  />
                )
            )}
        </div>
      </div>
    </Layout>
  )
}
export default Portfolio

export const PortfolioIndexQueryForEn = graphql`
  query PortfolioIndexQueryForEn {
    microcmsPortfolioEn {
      portfolio {
        title
        body
        image {
          url
        }
      }
      title
    }
  }
`
